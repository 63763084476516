
// @ts-nocheck


export const localeCodes =  [
  "nl",
  "en",
  "de"
]

export const localeLoaders = {
  "nl": [{ key: "../config/language/nl.js", load: () => import("../config/language/nl.js" /* webpackChunkName: "locale__vercel_path0_config_language_nl_js" */), cache: true }],
  "en": [{ key: "../config/language/en.js", load: () => import("../config/language/en.js" /* webpackChunkName: "locale__vercel_path0_config_language_en_js" */), cache: true }],
  "de": [{ key: "../config/language/de.js", load: () => import("../config/language/de.js" /* webpackChunkName: "locale__vercel_path0_config_language_de_js" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.js?hash=c231e734&config=1" /* webpackChunkName: "__i18n_config_js_c231e734" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.js",
  "locales": [
    {
      "code": "nl",
      "files": [
        "config/language/nl.js"
      ]
    },
    {
      "code": "en",
      "files": [
        "config/language/en.js"
      ]
    },
    {
      "code": "de",
      "files": [
        "config/language/de.js"
      ]
    }
  ],
  "defaultLocale": "nl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "config/language/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "contact": {
      "en": "/contact",
      "de": "/kontakt",
      "nl": "/contact"
    },
    "faq": {
      "en": "/faq",
      "de": "/faq",
      "nl": "/faq"
    },
    "gallery": {
      "en": "/gallery",
      "de": "/galerie",
      "nl": "/galerij"
    },
    "highlights": {
      "en": "/highlights",
      "de": "/sehenswurdigkeiten",
      "nl": "/bezienswaardigheden"
    },
    "index": {
      "en": "/",
      "de": "/",
      "nl": "/"
    },
    "news/index": {
      "en": "/news",
      "de": "/nachrichten",
      "nl": "/nieuws"
    },
    "news/[item]": {
      "en": "/news/[item]",
      "de": "/nachrichten/[item]",
      "nl": "/nieuws/[item]"
    },
    "packages/index": {
      "en": "/special-offers",
      "de": "/arrangements",
      "nl": "/arrangementen"
    },
    "packages/[item]": {
      "en": "/special-offers/[item]",
      "de": "/arrangements/[item]",
      "nl": "/arrangementen/[item]"
    },
    "privacy": {
      "en": "/cookie-policy",
      "de": "/cookie-richtlinie",
      "nl": "/cookiebeleid"
    },
    "reviews": {
      "en": "/reviews",
      "de": "/rezensionen",
      "nl": "/beoordelingen"
    },
    "rooms/index": {
      "en": "/rooms",
      "de": "/zimmer",
      "nl": "/kamers"
    },
    "rooms/[item]": {
      "en": "/rooms/[item]",
      "de": "/zimmer/[item]",
      "nl": "/kamers/[item]"
    },
    "sitemap": {
      "en": "/sitemap",
      "de": "/sitemap",
      "nl": "/sitemap"
    },
    "team": {
      "en": "/team",
      "de": "/team",
      "nl": "/team"
    },
    "vacancies/index": {
      "en": "/job-vacancies",
      "de": "/stellenangebote",
      "nl": "/vacatures"
    },
    "vacancies/[item]": {
      "en": "/job-vacancies/[item]",
      "de": "/stellenangebote/[item]",
      "nl": "/vacatures/[item]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "nl",
    "files": [
      {
        "path": "config/language/nl.js"
      }
    ]
  },
  {
    "code": "en",
    "files": [
      {
        "path": "config/language/en.js"
      }
    ]
  },
  {
    "code": "de",
    "files": [
      {
        "path": "config/language/de.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
